import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Button, Input, Checkbox, HtmlInputrops } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import getQueryVariable, { getAcrValue, getSource } from '../utility/getQueryVariable';
import ExternalProviders, { Providers } from '../externalproviders/ExternalProviders';
import Loader from '../utility/Loader';
import { History, Location } from 'history';

import './Login.scss';
import Background from '../Background';

export type IndependentUserLoginComponentProps = {
  t: any;
  history: History<void>;
  location: Location<void>;
};

type IndependentUserLoginComponentState = {
  username: string;
  password: string;
  errorMessage: string;
  validCredentials: boolean;
  performingLogin: boolean;
  passwordInputType: 'password' | 'text';
  clientId: string;
  errorFromQuery: string;
  returnUrl: string;
  source?: string;
  emailAddress: string;
  companyName: string;
  logoutUrl: string;
};

type IndependentUserLoginResponse = { redirectUrl: string; selectAccount: boolean };

class IndependentUserLoginComponent extends Component<IndependentUserLoginComponentProps, IndependentUserLoginComponentState> {
  constructor(props: IndependentUserLoginComponentProps) {
    super(props);

    this.logIn = this.logIn.bind(this);
    this.usernameOnchange = this.usernameOnchange.bind(this);
    this.passwordOnchange = this.passwordOnchange.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.isValidCredentials = this.isValidCredentials.bind(this);
    this.getSignupSource = this.getSignupSource.bind(this);

    const errorFromQuery = getQueryVariable('loginError');

    this.state = {
      username: '',
      password: '',
      validCredentials: false,
      performingLogin: false,
      errorMessage: errorFromQuery || null,
      passwordInputType: 'password',
      clientId: getQueryVariable('clientId'),
      errorFromQuery,
      returnUrl: getQueryVariable('returnUrl'),
      emailAddress: getQueryVariable('emailAddress'),
      companyName: getQueryVariable('companyName'),
      logoutUrl: ''
    };
  }

  async componentDidMount() {
    document.title = this.props.t('LoginTitle');

    this.setState({ source: getSource(this.state.returnUrl) });
    this.setState({ username: this.state.emailAddress });

    const { data } = await Axios<{ webUrl: string }>('/authenticate/weburl');
    if (data) {
      const logoutUrl = new URL('/set-logout', data.webUrl);
      this.setState({ logoutUrl: logoutUrl.href });
    }
  }

  getSignupSource() {
    return getAcrValue(this.state.returnUrl, 'signupsource');
  }

  usernameOnchange(e: React.ChangeEvent<HtmlInputrops>) {
    const usernameValue = e.target.value;
    this.setState({ username: usernameValue });
    this.isValidCredentials(usernameValue, this.state.password);
  }

  passwordOnchange(e: React.ChangeEvent<HtmlInputrops>) {
    const passwordValue = e.target.value;
    this.setState({ password: passwordValue });
    this.isValidCredentials(this.state.username, passwordValue);
  }

  showPassword() {
    if (this.state.passwordInputType === 'password') {
      this.setState({ passwordInputType: 'text' });
    } else {
      this.setState({ passwordInputType: 'password' });
    }
  }

  formSubmit(e: React.SyntheticEvent) {
    (document.activeElement as HTMLInputElement)?.blur();
    e.preventDefault();
    this.logIn();
  }

  isValidCredentials(email: string, password: string) {
    const pattern = new RegExp(/^(.+@.+)/i);

    if (pattern.test(email) && password.length > 0) {
      this.setState({ validCredentials: true });
      return;
    }
    this.setState({ validCredentials: false });
  }

  onExternalLogin = (providerId: Providers) => {
    this.setState({ performingLogin: true });

    //currently all external providers are oauth
    const generatedForm = document.createElement('form');
    generatedForm.action = `${window.location.origin}/authenticate/oauthlogin`;
    generatedForm.method = 'POST';

    try {
      const data = {
        returnUrl: this.state.returnUrl,
        clientId: this.state.clientId,
        environment: this.state.source,
        authorizationFailedReturnUrl: window.location.href,
        providerId
      };

      for (const property in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(property) && !!data[property]) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = property;
          input.value = data[property];
          generatedForm.appendChild(input);
        }
      }

      document.body.appendChild(generatedForm);
      generatedForm.submit();
    } catch (err) {
      const errorMessage = err?.response?.data?.message || this.props.t('UnknownError');
      this.setState({ performingLogin: false });
      this.setState({ errorMessage });
    }
  };

  async logIn() {
    this.setState({ performingLogin: true });
    const username = this.state.username;
    const password = this.state.password;
    const clientId = this.state.clientId;
    const returnUrl = this.state.returnUrl;

    try {
      const { data } = await Axios<IndependentUserLoginResponse>('/authenticate/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          username: username,
          password: password,
          clientId: clientId,
          returnUrl: returnUrl
        }
      });

      if (!data) {
        // throw error?
        return;
      }

      // you're an IU, take you to the account selection page
      if (!!data.selectAccount) {
        this.props.history.replace({ pathname: '/accounts', search: `${this.props.location.search}` });
        return;
      }
      // you have one client or are not an IU
      window.location.replace(data.redirectUrl);
    } catch (err) {
      const errorMessage = err?.response?.data?.message || this.props.t('UnknownError');
      this.setState({ performingLogin: false });
      this.setState({ errorMessage });
    }
  }

  returnToLogin = async () => {
    try {
      this.setState({ performingLogin: true });
      await addLogoutIframe(this.state.logoutUrl);
      await Axios<void>('authenticate/accountlogout', { method: 'POST', data: {} });
      window.location.replace('/login');
    } catch (err) {
      const errorMessage = err?.response?.data?.message || this.props.t('UnknownError');
      this.setState({ performingLogin: false });
      this.setState({ errorMessage });
    }
  };

  render() {
    if (this.state.performingLogin) {
      return <Loader isLoading />;
    }

    return (
      <div className="login">
        <Background />
        <div className="content">
          {/* TODO - replace with GfLogo component */}
          <div className="card">
            <div className="header">
              <span>{this.props.t('LoginHeader')}</span>
              <br />
              <span className="company-name">{this.state.companyName}</span>
            </div>

            {this.state.errorMessage ? (
              <div className="form-group">
                <div className="error-state">{this.state.errorMessage}</div>
              </div>
            ) : null}

            <div className="goformz-provider">
              <form name="login" onSubmit={this.formSubmit} className="login-form">
                <Input type="email" name="username" id="username" value={this.state.emailAddress} autoFocus disabled />
                <Input
                  key={this.state.passwordInputType}
                  className="password-input"
                  type={this.state.passwordInputType}
                  name="password"
                  id="password"
                  placeholder={this.props.t('PasswordFieldPlaceholder')}
                  value={this.state.password}
                  onChange={(e) => this.passwordOnchange(e)}
                />
                <div className="password-controls">
                  <div className="show-password">
                    <Checkbox label={this.props.t('ShowPassword')} onChange={this.showPassword} checked={this.state.passwordInputType === 'text'} />
                  </div>
                  <div className="forgot-password">
                    <Link to={`/forgotpassword?ReturnUrl=${encodeURIComponent(this.state.returnUrl)}`}>{this.props.t('ForgotPassword')}</Link>
                  </div>
                </div>
                <div className="login-button">
                  <Button className="ui primary button" id="login" type="submit" disabled={!this.state.validCredentials}>
                    {this.props.t('LoginButton')}
                  </Button>
                </div>
              </form>
            </div>

            <ExternalProviders showLine={true} text={this.props.t('LoginWith')} onProviderClick={this.onExternalLogin}></ExternalProviders>

            <div className="form-secondary">
              <div className="mobile-action-buttons">
                <a role="button" onClick={() => this.returnToLogin()} className="action-button mobile">
                  Return to Login
                </a>
              </div>
            </div>
          </div>
          <div className="login-footer sso-footer">
            <a role="button" onClick={() => this.returnToLogin()}>
              Return to Login
            </a>
          </div>
        </div>
      </div>
    );
  }
}

async function addLogoutIframe(url: string) {
  const iframe = document.createElement('iframe');
  const loadPromise = new Promise((resolve) => {
    iframe.onload = resolve;
  });
  iframe.width = '0';
  iframe.height = '0';
  iframe.src = url;
  document.body.appendChild(iframe);

  return await loadPromise;
}

export const IndependentUserLogin = withTranslation()(IndependentUserLoginComponent);
