const VALID_PROTOCOLS = ['http:', 'https:'];

const isValidUrl = (urlString: string) => {
  try {
    const url = new URL(urlString);
    return !!url?.origin && VALID_PROTOCOLS.includes(url.protocol);
  } catch (e) {
    return false;
  }
};

export function isSafeUrl(url: string) {
  // if the url is null or empty then its considered safe
  if (!url) {
    return true;
  }

  // if there url does not pass the javascript constructor then its unsafe and likely has an attack script
  // this will also attempt to decode the url in case an encoded url is used
  return isValidUrl(url) || isValidUrl(decodeURI(url));
}

export function getSafeUrlOrRoot(url: string) {
  return isSafeUrl(url) ? url : window.location.origin;
}
 