import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import getQueryVariable, { getAcrValue } from '../utility/getQueryVariable';
import getCookie from '../utility/getCookie';
import { analyticsIdentify, analyticsTrack } from '../utility/segment';
import { useForm } from '../../hooks/useForm';
import { ValidationInput, ValidationSelect } from './ValidationFields';
import './SignupSurvey.scss';
import Background from '../Background';

export function SignupSurvey() {
  const returnUrl = getQueryVariable('returnUrl');
  const emailAddress = getQueryVariable('emailAddress');
  const firstName = getQueryVariable('firstName');
  const lastName = getQueryVariable('lastName');
  const phoneNumber = getQueryVariable('phoneNumber');
  const signupCode: string = getQueryVariable('signupCode');
  const [signupSource, setSignupSource] = useState<string>();
  const [showPromoField, setShowPromoField] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [reCaptchaV2Token, setReCaptchaV2Token] = useState();
  const [reCaptchaV2RenderId, setReCaptchaV2RenderId] = useState();
  const [isReCaptchaV2TokenValid, setIsReCaptchaV2TokenValid] = useState(false);
  const [reCaptchaV2TokenValidationTime, setReCaptchaV2TokenValidationTime] = useState<Date>(null);
  const [reCaptchaV2TokenCheckTimer, setReCaptchaV2TokenCheckTimer] = useState(null);
  const [reCaptchaV3Token, setReCaptchaV3Token] = useState();
  const [reCaptchaV3RenderId, setReCaptchaV3RenderId] = useState();
  const [reCaptchaV3QualifyingScore, setReCaptchaV3QualifyingScore] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const enforceReCaptchaV2 = reCaptchaV2RenderId != null && reCaptchaV2Token != null;



  const { t } = useTranslation();

  const [formState, setFormValue] = useForm({
    emailAddress: emailAddress || '',
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    industry: '',
    company: '',
    companySize: '',
    promoCode: signupCode || '',
    marketoCookie: getCookie('_mkto_trk'),
    siftSessionId: sessionStorage.getItem('siftSessionId'),
    TimeZone: moment.tz.guess()
  });

  const shouldTrack = ['NSl1ej5Opp'].includes(formState?.promoCode) === false;

  const [isBlurred, setBlurred] = useForm({
    lastName: false,
    industry: false,
    company: false,
    companySize: false
  });

  useEffect(() => {
    document.title = t('SurveyTitle');

    const source = getSignupSource();
    setSignupSource(source);
    loadReCaptchaToken();
  }, []);


  useEffect(() => {
    //handles updating the token for v2 on an interval and expiring it after 90s
    const setupReInvoke = () => { 
      const timeoutId = setTimeout(() => {setReCaptchaV2TokenCheckTimer(timeoutId)}, 250);
      return () => { clearTimeout(timeoutId); };
    }
  
    if(reCaptchaV2RenderId == null) { 
      return setupReInvoke();
    }

    const now = new Date();
    const timeSinceVerification = reCaptchaV2TokenValidationTime == null ? null : reCaptchaV2TokenValidationTime.valueOf() - now.valueOf();

    //if we havent verified in 90 seconds then force a reverification
    if(timeSinceVerification != null && timeSinceVerification > (90 *  1000)) { 
      setReCaptchaV2TokenValidationTime(null);
      setIsReCaptchaV2TokenValid(false);
      window['grecaptcha'].reset(reCaptchaV2RenderId);
      return setupReInvoke();
    }

    
    if(reCaptchaV2TokenValidationTime == null) { 
      setReCaptchaV2TokenValidationTime(now);
    }
    
    const reCaptchaV2ResponseToken = window['grecaptcha'].getResponse(reCaptchaV2RenderId);
    const isTokenValid = reCaptchaV2ResponseToken != null && reCaptchaV2ResponseToken !== "";
    setIsReCaptchaV2TokenValid(isTokenValid);  
    return setupReInvoke();

  }, [reCaptchaV2TokenCheckTimer]);

  async function loadReCaptchaToken() {
    setShowLoading(true);
    try {
      const result = await Axios('/settings/recaptcha', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });

      const v2SiteKey = result?.data?.v2?.siteKey;
      const v3SiteKey = result?.data?.v3?.siteKey;
      setReCaptchaV3QualifyingScore(result?.data?.v3?.qualifyingScore);
      setReCaptchaV2Token(v2SiteKey);
      setReCaptchaV3Token(v3SiteKey);
      trySetContainerForRecaptchaV3(v3SiteKey);
    } catch (error) {
      console.log('RecaptchaKey not found');
    }
    setShowLoading(false);
  }

  const trySetContainerForRecaptchaV2 = (siteKey: string) => {
    const container = document.getElementById('recaptchav2-container');
    if (container == null) {
      return;
    }

    window['onRecaptchaCapturev2'] = () => {
      //this is just to satisfy the recaptcha lib 
    }

    if (reCaptchaV2RenderId == null && siteKey != null) {
      setReCaptchaV2RenderId(window['grecaptcha'].render(container, {
        sitekey: siteKey,
        callback: 'onRecaptchaCapturev2',
      }));
    }
  }

  const trySetContainerForRecaptchaV3 = (siteKey: string) => {
    const container = document.getElementById('recaptchav3-container');
    if (container == null) {
      return;
    }

    window['onRecaptchaCapturev3'] = () => {
      //this is just to satisfy the recaptcha lib 
    }

    if (reCaptchaV3RenderId == null && siteKey != null) {
      setReCaptchaV3RenderId(window['grecaptcha'].render(container, {
        sitekey: siteKey,
        size: 'invisible',
        callback: 'onRecaptchaCapturev3',
      }));
    }
  }


  function isFieldValid(fieldName: string, trim = false) {
    if (!trim) {
      return formState[fieldName] || !isBlurred[fieldName];
    }

    const value = !!(formState[fieldName]?.trim());

    return value || !isBlurred[fieldName];
  }

  function isFormValid() {
    if(enforceReCaptchaV2 && !isReCaptchaV2TokenValid) { 
      return false;
    }

    const firstName = !!formState.firstName?.trim();
    const lastName = !!formState.lastName?.trim();
    const company = !!formState.company?.trim();

    return firstName && lastName && company && formState.companySize && formState.industry;
  }

  function getSignupSource() {
    return getAcrValue(returnUrl, 'signupsource');
  }

  
  const getReCaptchaHeader = (headers) => { 
    return Object.entries(headers || {}).find(arr => arr != null && arr.length > 1 && arr[0].toLowerCase() === 'recaptcha');
  }

  const getReCaptchaScoreFromHeader = (headers) => { 
    const reCAPTCHAHeader = getReCaptchaHeader(headers);
    const scoreValue = reCAPTCHAHeader == null ? null : reCAPTCHAHeader[1];
    const parsedScore = Number.parseFloat(scoreValue as string);
    if(Number.isNaN(parsedScore)) { 
      return null;
    }

    return parsedScore;
  }

  

  const trackReCAPTCHA = (headers, userId: string = null) => { 
    if(headers == null) { 
      return;
    }

    const score = getReCaptchaScoreFromHeader(headers);
    if(score != null && shouldTrack) {
      analyticsIdentify(userId, { ReCAPTCHA: score });
    }
  }

  const trackAccountCreated = async (data) => {
    await analyticsIdentify(data.userId, {
      usagedataid: data.clientId, //legacy clientId
      clientId: data.clientId,
      groupId: data.clientId, //some segment destinations only allow groupId
      email: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
    }); //ensure that we have an identification for the user bc it could be anonymous up til now

    await analyticsTrack('Account Created', { email: data.userName, source: signupSource });
  };

  const shouldEnforceRecaptchaV2 = (headers) => { 
    if(headers == null) { 
      return false;
    }

    const score = getReCaptchaScoreFromHeader(headers);
    if(reCaptchaV3QualifyingScore == null) { 
      return false;
    }

    return score == null ? false : score < reCaptchaV3QualifyingScore;
  }


  const promoCodeEnabled = () => {
    // iOS AppStore does not allow promo codes in signup page. Goes against their TOS
    // signupCode don't show promo code if prepopulated from query string
    if (signupSource === 'ios' || signupCode != null) {
      return false;
    }
    return true;
  };

  const onSubmit = async (recaptchaTokens: { v3Token?: string, v2Token?: string }) => {
    setShowLoading(true);
    setErrorMessage(null);

    if (!isFormValid()) {
      setShowLoading(false);
      return;
    }

    try {
      const { data, headers } = await Axios('/authenticate/signup', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          requestData: { ...formState, reCaptchaV3Token: recaptchaTokens?.v3Token, reCaptchaV2Token: recaptchaTokens?.v2Token }
        }
      });

      if (data) {
        //only track recaptcha if not using a v2 token
        recaptchaTokens?.v2Token == null && trackReCAPTCHA(headers, data.userId);

        //awaiting this to make sure the account created is actually fired before navigating them away
        //this makes sure all data layer (GTM) tags have fired
        await trackAccountCreated(data);
        window.location = data.redirectUrl;
      }
    } catch (err) {
      //only track recaptcha if not using a v2 token
      recaptchaTokens?.v2Token == null && trackReCAPTCHA(err.response?.headers);
      setShowLoading(false);

      if(shouldEnforceRecaptchaV2(err.response?.headers)) {
        return trySetContainerForRecaptchaV2(reCaptchaV2Token);
      }
      setErrorMessage(err.response.data.message || t('UnknownError'));
    }
  }

  function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    //if v2 is rendered but the token is invalid, then skip
    if(enforceReCaptchaV2 && !isReCaptchaV2TokenValid) { 
      return;
    }
    
    //if v2 is rendered then submit with that token
    if(enforceReCaptchaV2) {       
      const reCaptchaV2ResponseToken = window['grecaptcha'].getResponse(reCaptchaV2RenderId);
      return onSubmit({v2Token: reCaptchaV2ResponseToken });
    }
    
    //else fall back to v3
    if(reCaptchaV3RenderId != null && reCaptchaV3Token != null) { 
      return window['grecaptcha'].execute(reCaptchaV3RenderId).then((token) => onSubmit({ v3Token: token })).catch(e => {
        console.error('RecaptchaKey failed: ', e);
      });      
    }

    //else just submit
    return onSubmit(null);
  }

  return (
    <div className="signup-survey">
      <Background />
      <div className="content">
        <div className="logo outside-card" onClick={() => window.location.assign('https://goformz.com')} />
        <div className="card">
          <div className="header">
            <span>{t('SurveyHeader')}</span>
          </div>

          {errorMessage ? <div className="error-state">{errorMessage}</div> : null}

          <form name="signupsurvey" onSubmit={onFormSubmit} className="survey">
            <div>
              <div className="row col2">
                <ValidationInput
                  fieldName="firstName"
                  validationText={t('SignupValidationMessages.FirstNameRequired')}
                  placeholder={t('SignupPlaceholders.FirstName')}
                  value={formState.firstName}
                  setFormValue={setFormValue}
                  isValid={(field) => isFieldValid(field, true)}
                  setBlurred={setBlurred}
                  autoFocus={true}
                />

                <ValidationInput
                  fieldName="lastName"
                  validationText={t('SignupValidationMessages.LastNameRequired')}
                  placeholder={t('SignupPlaceholders.LastName')}
                  value={formState.lastName}
                  isValid={(field) => isFieldValid(field, true)}
                  setFormValue={setFormValue}
                  setBlurred={setBlurred}
                />
              </div>

              <div className="row">
                <ValidationInput
                  fieldName="phoneNumber"
                  validationText="x"
                  placeholder={t('SignupPlaceholders.Phone')}
                  value={formState.phoneNumber}
                  setFormValue={setFormValue}
                  setBlurred={setBlurred}
                />
              </div>

              <div className="row">
                <ValidationInput
                  fieldName="company"
                  validationText={t('SignupValidationMessages.CompanyRequired')}
                  placeholder={t('SignupPlaceholders.Company')}
                  value={formState.company}
                  setFormValue={setFormValue}
                  isValid={(field) => isFieldValid(field, true)}
                  setBlurred={setBlurred}
                />
              </div>

              <div className="row col2">
                <ValidationSelect
                  fieldName="companySize"
                  validationText={t('SignupValidationMessages.CompanySizeRequired')}
                  placeholder={t('SignupPlaceholders.CompanySize')}
                  value={formState.companySize}
                  setFormValue={setFormValue}
                  setBlurred={setBlurred}
                  isValid={isFieldValid}
                  options={[
                    { value: '1 - 9', display: t('CompanySizeOptions.1 - 9') },
                    { value: '10 - 49', display: t('CompanySizeOptions.10 - 49') },
                    { value: '50 - 99', display: t('CompanySizeOptions.50 - 99') },
                    { value: '100 - 999', display: t('CompanySizeOptions.100 - 999') },
                    { value: '1,000 - 9,999', display: t('CompanySizeOptions.1,000 - 9,999') },
                    { value: '10,000+', display: t('CompanySizeOptions.10,000+') }
                  ]}
                />
                <ValidationSelect
                  fieldName="industry"
                  validationText={t('SignupValidationMessages.IndustryRequired')}
                  placeholder={t('SignupPlaceholders.Industry')}
                  value={formState.industry}
                  isValid={isFieldValid}
                  setFormValue={setFormValue}
                  setBlurred={setBlurred}
                  options={[
                    { value: 'Agriculture', display: t('IndustryOptions.Agriculture') },
                    { value: 'Beauty & Wellness', display: t('IndustryOptions.Beauty & Wellness') },
                    { value: 'Construction', display: t('IndustryOptions.Construction') },
                    { value: 'Education', display: t('IndustryOptions.Education') },
                    { value: 'Energy or Utilities', display: t('IndustryOptions.Energy or Utilities') },
                    { value: 'Engineering', display: t('IndustryOptions.Engineering') },
                    { value: 'Environmental Services', display: t('IndustryOptions.Environmental Services') },
                    { value: 'Field Service', display: t('IndustryOptions.Field Service') },
                    { value: 'Finance or Real Estate', display: t('IndustryOptions.Finance or Real Estate') },
                    { value: 'Food & Beverage', display: t('IndustryOptions.Food & Beverage') },
                    { value: 'Government or Non-Profit', display: t('IndustryOptions.Government or Non-Profit') },
                    { value: 'Healthcare', display: t('IndustryOptions.Healthcare') },
                    { value: 'Hospitality, Entertainment, Recreation', display: t('IndustryOptions.Hospitality, Entertainment, Recreation') },
                    { value: 'HVAC', display: t('IndustryOptions.HVAC') },
                    { value: 'Manufacturing or Machinery', display: t('IndustryOptions.Manufacturing or Machinery') },
                    { value: 'Media & Communications', display: t('IndustryOptions.Media & Communications') },
                    { value: 'Professional Services or Consulting', display: t('IndustryOptions.Professional Services or Consulting') },
                    { value: 'Repair & Maintenance', display: t('IndustryOptions.Repair & Maintenance') },
                    { value: 'Retail', display: t('IndustryOptions.Retail') },
                    { value: 'Technology', display: t('IndustryOptions.Technology') },
                    { value: 'Transportation & Logistics', display: t('IndustryOptions.Transportation & Logistics') },
                    { value: 'Other', display: t('IndustryOptions.Other') }
                  ]}
                />
              </div>

              <div className="row">
                {showPromoField ? (
                  <ValidationInput
                    fieldName="promoCode"
                    validationText="x"
                    placeholder={t('SignupPlaceholders.Promo Code')}
                    value={formState.promoCode}
                    setFormValue={setFormValue}
                  />
                ) : null}
              </div>

              <div className="row promotion">
                {!showPromoField && promoCodeEnabled() ? (
                  <a className="button-link" onClick={() => setShowPromoField(true)}>
                    {t('AddPromoCodeButton')}
                  </a>
                ) : null}
              </div>
            </div>

            <div className="get-started">
              <div key="recaptchav3-container" id="recaptchav3-container" className="g-recaptcha grecaptchav3-container"></div>
              <div key="recaptrecaptchav2-container" id="recaptchav2-container" className="g-recaptcha grecaptchav2-container"></div>
              <Button disabled={!isFormValid()} loading={showLoading} type="submit" className="ui primary button ">
                {t('SignupButton')}
              </Button>
            </div>
          </form>
          <div className="terms-of-service">
            <div
              dangerouslySetInnerHTML={{
                __html: t('TermsOfServiceAgreementText', {
                  tosLink: `<a href="https://www.goformz.com/terms" target="_blank" class="terms-text">${t('TermsOfServiceLink')}</a>`
                })
              }}
            />
            <div>
              This site is protected by reCAPTCHA. The Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
