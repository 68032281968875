import React, { Component } from 'react';
import './ForgotPassword.scss';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Button, Input } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import ErrorPage from '../error/ErrorPage';
import getQueryVariable from '../utility/getQueryVariable';
import { getSafeUrlOrRoot } from '../utility/urlSanitization';
import Background from '../Background';

export type ForgotPasswordComponentProps = {
  t: any;
};

type ForgotPasswordComponentState = {
  emailAddress: string;
  error: boolean;
  errorMessage: string;
  resetSuccess: boolean;
  unknownError: boolean;
  returnUrl: string;
  performingForgotPassword: boolean;
  invalidEmail?: boolean;
  redirectUrl?: string;
  profileUrl?: string;
};

// NOTE:
//  redirect_url = passed in by the app or mobile User Profile "change password" flow.
//  returnUrl = passed in when the user is not logged in and a user selects Forgot Password from the login screen
class ForgotPasswordComponent extends Component<ForgotPasswordComponentProps, ForgotPasswordComponentState> {
  constructor(props: ForgotPasswordComponentProps) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      emailAddress: getQueryVariable('email') ? getQueryVariable('email') : '',
      error: false,
      errorMessage: '',
      resetSuccess: getQueryVariable('resetSuccess'),
      profileUrl: getSafeUrlOrRoot(getQueryVariable('profileUrl')),
      unknownError: false,
      returnUrl: getSafeUrlOrRoot(getQueryVariable('ReturnUrl')),
      performingForgotPassword: false
    };
  }

  async onSubmit(e: React.SyntheticEvent) {
    const { emailAddress, returnUrl } = this.state;

    e.preventDefault();
    this.setState({ performingForgotPassword: true });

    if (emailAddress.length < 1) {
      this.setState({ invalidEmail: true });
    } else {
      try {
        await Axios('/authenticate/forgotpassword', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data: {
            emailAddress,
            returnUrl
          }
        });
        this.setState({ resetSuccess: true });
      } catch (err) {
        if (err.response.status >= 500) {
          this.setState({ unknownError: true, errorMessage: err.response.data.message ? err.response.data.message : null });
        }
        this.setState({ error: true, errorMessage: err.response.data.message, performingForgotPassword: false });
      }
      this.setState({ performingForgotPassword: false });
    }
  }

  emailAddressOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ emailAddress: e.target.value });
  }

  render() {
    if (this.state.unknownError) {
      return <ErrorPage errorMessage={this.state.errorMessage} />;
    }
    let body = (
      <div className="forgot content">
        <div className="top-row header">
          <div className="logo" />
          <div className="logo-wrap-large">
            <div className="logo" />
          </div>
        </div>
        <div className="form-card">
          <header style={{ flexDirection: 'column' }}>
            <h1 className="forgotpassword-text">{this.props.t('ForgotPassword')}</h1>
          </header>
          <form name="login" onSubmit={this.onSubmit} className="forgot-form">
            {this.state.error ? (
              <div className="form-group">
                <div className="error-state">{this.state.errorMessage}</div>
              </div>
            ) : null}
            <div className="form-group">
              <Input
                className="form-control"
                type="email"
                name="email"
                id="email"
                placeholder={this.props.t('UsernameFieldPlaceholder')}
                value={this.state.emailAddress}
                onChange={(e) => this.emailAddressOnChange(e)}
                autoFocus
              />
            </div>
            <Button
              className="primary-button"
              id="login"
              type="submit"
              disabled={!this.state.emailAddress.includes('@')}
              loading={this.state.performingForgotPassword}
            >
              {this.props.t('ResetPasswordButton')}
            </Button>
          </form>
        </div>
      </div>
    );

    if (this.state.resetSuccess) {
      body = (
        <div className="forgot content">
          <div className="top-row header">
            <div className="logo" />
            <div className="logo-wrap-large">
              <div className="logo" />
            </div>
          </div>
          <div className="form-card">
            <h1 className="forgotpassword-text success">{this.props.t('RequestReceived')}</h1>
            <div className="check-email-text">{this.props.t('CheckEmail')}</div>
            {this.state.profileUrl ? (
              <Button className="primary-button" onClick={() => window.location.replace(this.state.profileUrl)}>
                {this.props.t('ReturnToProfile')}
              </Button>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div className="forgotpassword">
        <Background />
        <div className="logo-wrap">
          <div className="logo" />
        </div>
        {body}
        <div className="forgotpassword-footer" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="forgotpassword-footer-item">
            {this.state.profileUrl ? null : (
              <Link to={`/login?ReturnUrl=${encodeURIComponent(this.state.returnUrl)}`} className="forgotpassword-footer-text">
                {this.props.t('ReturnToLoginLink')}
              </Link>
            )}
          </div>
        </div>
        <div className="bottom-wrap" />
      </div>
    );
  }
}

export const ForgotPassword = withTranslation()(ForgotPasswordComponent);
