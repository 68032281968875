import React, { useState } from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import './EmailInput.scss';

export type EmailInputProps = {
  ignoreValidation?: boolean;
  value: string;

  onEmailChange: (value: string, isValid?: boolean) => void;
  onChange?: (value: string) => void;
};

export default function EmailInput(props: EmailInputProps) {
  const [emailCheckFetching, setEmailStateFetching] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>();
  const [emailSuggestion, setEmailSuggestion] = useState<string>();
  const [hasValidated, setHasValidated] = useState(false);

  const { t } = useTranslation();

  async function validateEmail(emailAddress) {
    if (!emailAddress) {
      //cute lil hack that will put this call on top of the js stack
      //so that to allow the consumer to set the ignoreValiation flag and the validation message wont flash due to something like unfocus
      setTimeout(function () {
        setValidationMessage(t('SignupValidationMessages.EmailAddressRequired'));
      }, 100);

      setIsValid(false);
      setEmailSuggestion('');
      props.onEmailChange('');
      return;
    }

    try {
      setEmailStateFetching(true);
      const email = encodeURIComponent(emailAddress);
      const { data } = await Axios(`/authenticate/validations/email?emailAddress=${email}`, {
        method: 'GET',
        headers: { Accept: 'application/json' }
      });
      setEmailStateFetching(false);
      setIsValid(data.isValid);
      setValidationMessage(data.isValid && !data.didYouMeanEmail ? null : data.message);
      setEmailSuggestion(data.didYouMeanEmail);
      props.onEmailChange(emailAddress, data.isValid);
    } catch (err) {
      // don't let unhandled exceptions prevent the user from signing up.
      setEmailStateFetching(false);
      setIsValid(true);
      props.onEmailChange(emailAddress, true);
    }

    setHasValidated(true);
  }

  async function applySuggestion() {
    const suggestedEmail = emailSuggestion;
    props.onChange(suggestedEmail);
    await validateEmail(suggestedEmail);
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.onEmailChange(e.target.value, false);
  }

  async function onBlur(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    await validateEmail(e.target.value);
  }

  function emailStatusIcon() {
    if (hasValidated && isValid && !emailCheckFetching && emailSuggestion) {
      return 'warningcheck';
    }
    if (hasValidated && isValid && !emailCheckFetching && !emailSuggestion) {
      return 'success';
    }
    if (hasValidated && !isValid && !emailCheckFetching) {
      return 'invalid';
    }
    return '';
  }

  return (
    <div className="email-input validationContainer">
      <div className="validationText">
        {!props.ignoreValidation && !isValid && !emailSuggestion && !emailCheckFetching ? <div>{validationMessage}</div> : null}
        {emailSuggestion ? (
          <button className="button-link" onClick={applySuggestion}>
            {validationMessage}
          </button>
        ) : null}
      </div>
      <Input
        className={`validationInput ${isValid ? '' : ' validationInputInvalid'}`}
        onChange={onChange}
        onBlur={onBlur}
        value={props.value}
        type="email"
        placeholder={t('SignupPlaceholders.EmailAddress')}
        required
        loading={emailCheckFetching}
        icon={emailStatusIcon()}
        data-lpignore="true"
        autoComplete="off"
        autoFocus
        maxLength={100}
      />
    </div>
  );
}
