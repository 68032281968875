import Axios from 'axios';

export type RedirectType = 'signup';

export default async function redirectToWebApp(type?: RedirectType) {
  try {
    const { data } = await Axios('/authenticate/weburl', {
      method: 'GET',
      /* @ts-ignore */
      credentials: 'include'
    });

    if (data) {
      let url = data.webUrl;
      if (type === 'signup') {
        url += '?signup=true';
      }
      window.location = url;
    }
  } catch (err) {
    window.location.pathname = `/error?message=${encodeURIComponent('(or try returning to the web app and click on Login)')}`;
  }
}
