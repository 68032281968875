import Axios, { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import { IndependentUserClient } from './accounts-types';

export type ClientRequestError = { status: HttpStatusCode; message: string };

export type ClientResponse = {
  clients: IndependentUserClient[];
  isLoading: boolean;
  error?: ClientRequestError;
};

export function useClients() {
  const [clients, setClients] = useState<IndependentUserClient[]>();
  const [error, setError] = useState<ClientRequestError>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Axios<IndependentUserClient[]>('/authenticate/clients', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(({ data }) => {
        setClients(data);
      })
      .catch(({ response }) => {
        setError({ status: response.status, message: response.data?.message });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { clients, error, isLoading };
}
