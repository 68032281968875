import React from 'react';

export type LoaderProps = {
  isLoading?: boolean;
};

export default function Loader({ isLoading }: LoaderProps) {
  return (
    isLoading && (
      <div className="ui active inverted dimmer">
        <div className="ui loader" />
      </div>
    )
  );
}
