import React from 'react';
import { useTranslation } from 'react-i18next';
import './Error.scss';
import { EXTERNAL_PROVIDER_RETURN_URL_KEY } from '../../constants/LocalStorageConstants';
import getQueryVariable from '../utility/getQueryVariable';
import Background from '../Background';

export type ErrorPageProps = {
  errorMessage?: string;
};

export default function ErrorPage(props: ErrorPageProps) {
  const { t } = useTranslation();
  const redirectToProviderReturnUrl = getQueryVariable('redirectToProviderReturnUrl');
  const title = getQueryVariable('title') || t('ContactSupport');
  const errorMessage = getQueryVariable('message') || props.errorMessage;

  if (redirectToProviderReturnUrl) {
    const returnUri = localStorage.getItem(EXTERNAL_PROVIDER_RETURN_URL_KEY);

    if (returnUri) {
      window.location.replace(returnUri);
    }
  }

  const requestId = getQueryVariable('requestId');

  return (
    <div className="error">
      <Background />
      <div className="error-wrap">
        <div className="logo-wrap">
          <div className="logo" />
        </div>
        <div className="form-card error">
          <h1 className="error-header">{t('Error')}</h1>
          <div className="error-box-wrapper">
            <div className="error-subtext">{title}</div>
            {errorMessage ? <div className="error-message-text">{errorMessage} </div> : null}
            {requestId ? <div className="requestid">{`Request ID: ${requestId}`}</div> : null}
          </div>
        </div>
      </div>
      <div className="bottom-wrap" />
    </div>
  );
}
