import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import Loader from '../utility/Loader';

export default function SwitchAccount() {
  const history = useHistory();

  useEffect(() => {
    switchAccount();
  }, []);

  const switchAccount = async () => {
    try {
      await Axios('/authenticate/switchaccounts', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      history.replace({ pathname: '/accounts', search: `${location.search}` });
    } catch {
      history.replace('/login');
    }
  };

  return <Loader isLoading />;
}
