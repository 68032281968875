export default function getQueryVariable(variable: string) {
  const queryString = window.location.search.substring(1);
  return getVariableFromQueryString(queryString, variable);
}

export function getQueryVariableFromUrl(url: string, variable: string) {
  if (!url) return null;
  const parts = url.split('?');
  if (parts.length < 2) return null;
  return getVariableFromQueryString(parts[1], variable);
}

export function getVariableFromQueryString(queryString: string, variable: string) {
  let result = null;
  if (queryString && variable) {
    const vars = queryString.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]).toLowerCase() === variable.toLowerCase()) {
        try {
          const value = `${pair[1]}`.replace(/\+/g, '%20');
          result = decodeURIComponent(value);
          break;
        } catch (err) {
          return null;
        }
      }
    }
  }
  return result;
}

export function getAcrValues(returnUrl: string) : string[] {
  const acr = getQueryVariableFromUrl(returnUrl, 'acr_values');
  if (acr) {
    const acrValues = acr.split(' ');
    return acrValues;
  }
  return null;
}

export function getAcrValue(returnUrl: string, key: string) : string {
  const acrValues = getAcrValues(returnUrl);
  if (acrValues) {
      for (const acrValue of acrValues) {
          if (acrValue.includes(key)) {
              const source = acrValue.split(':');
              if (source.length > 1) {
                  return source[1];  // Return the part after the ':'
              }
          }
      }
  }
  return null;
}

export function getSource(returnUrl: string) : string {
  return getAcrValue(returnUrl, 'signupsource');
}
