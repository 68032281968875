import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import getQueryVariable from '../utility/getQueryVariable';
import redirectToWebApp from '../utility/redirectToWebApp';
import Loader from '../utility/Loader';
import './Sso.scss';
import Background from '../Background';

export type SsoComponentProps = {
  providerId?: string;
  redirectUrl?: string;
  clientId?: string;
  t: TranslateFunc;
};

type TranslateFunc = (key: string) => string;

type SsoComponentState = {
  error: string;
  errorMessage: string;
  providerId: string;
  returnUrl: string;
  clientId: string;
  requestId: string;
  ssoSubmitUrl: string;
  loading: boolean;
};

class SsoComponent extends Component<SsoComponentProps, SsoComponentState> {
  private t: TranslateFunc;

  constructor(props: SsoComponentProps) {
    super(props);
    this.providerIdOnChange = this.providerIdOnChange.bind(this);
    this.buttonClick = this.buttonClick.bind(this);
    this.state = {
      error: getQueryVariable('error'),
      errorMessage: getQueryVariable('error'),
      providerId: props.providerId ? props.providerId : '',
      returnUrl: props.redirectUrl ? props.redirectUrl : getQueryVariable('ReturnUrl'),
      clientId: props.clientId ? props.clientId : '',
      requestId: getQueryVariable('requestId'),
      ssoSubmitUrl: '/authenticate/ssologin',
      loading: false
    };

    this.t = props.t;

    if (!getQueryVariable('returnUrl')) {
      redirectToWebApp();
    }
  }

  componentDidMount() {
    document.title = this.t('SSOTitle');
    // if the provider ID was passed in, automatically submit the form
    // (this happens when using special access from the tools site)
    if (this.state.providerId) {
      const form = document.getElementById('loginForm') as HTMLFormElement;
      form.submit();
    }
  }

  providerIdOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ providerId: e.target.value });
  }

  buttonClick() {
    this.setState({ loading: true });
  }

  render() {
    const form = (
      <form id="loginForm" method="post" action={this.state.ssoSubmitUrl} className="sso-form">
        {this.state.error ? (
          <div className="form-group error-state">
            <div>{`${this.t('Error')}: ${this.state.errorMessage}`}</div>
            {this.state.requestId ? <div className="requestid">{`Request ID: ${this.state.requestId}`}</div> : null}
          </div>
        ) : null}
        <div className="form-group">
          <Input
            className="form-control"
            type="text"
            name="providerId"
            id="providerId"
            placeholder={this.t('SSOCompanyPlaceholder')}
            onChange={(e) => this.providerIdOnChange(e)}
            value={this.state.providerId}
            autoFocus
          />
          <Input name="returnUrl" type="hidden" className="returnUrl-hidden" value={this.state.returnUrl} />
          <Input name="clientId" type="hidden" className="returnUrl-hidden" value={this.state.clientId || ''} />
        </div>
        <div>
          <Button
            className="primary-button"
            id="login"
            type="submit"
            disabled={!this.state.providerId}
            onClick={() => this.buttonClick()}
            loading={this.state.loading}
          >
            {this.t('ContinueButton')}
          </Button>
          <div className="form-secondary">
            <div className="mobile-action-buttons">
              <Link to={`/login?ReturnUrl=${encodeURIComponent(this.state.returnUrl)}`} className="action-button mobile">
                {this.t('ReturnToLoginLink')}
              </Link>
            </div>
          </div>
        </div>
      </form>
    );
    if (this.props.providerId) {
      return (
        <React.Fragment>
          <Loader isLoading />
          <div style={{ display: 'none' }}>{form}</div>
        </React.Fragment>
      );
    }
    return (
      <div className="sso">
        <Background />
        <div className="sso content">
          <div className="top-row header">
            <div className="logo" />
            <div className="logo-wrap-large">
              <div className="logo" />
            </div>
          </div>
          <div className="form-card">
            <header style={{ flexDirection: 'column' }}>
              <h1 className="sso-text">{this.t('SSOHeader')}</h1>
              <h5 className="sso-subtext">{this.t('SSOInstructions')}</h5>
            </header>
            {form}
          </div>
        </div>
        <div className="sso-footer" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="sso-footer-item">
            <Link to={`/login?ReturnUrl=${encodeURIComponent(this.state.returnUrl)}`} className="sso-footer-text">
              {this.t('ReturnToLoginLink')}
            </Link>
          </div>
        </div>
        <div className="bottom-wrap" />
      </div>
    );
  }
}

export const Sso = withTranslation()(SsoComponent);
