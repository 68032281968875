export type PasswordValidationResult = {
  valid?: boolean;
  inCharacterLimit: boolean;
  hasUpperCaseLetter: boolean;
  hasLowerCaseLetter: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
  matchingPasswords: boolean;
};

export function validatePassword(passwordValue: string, useConfirmPassword: boolean, confirmPasswordValue: string): PasswordValidationResult {
  const upperCaseLetter = new RegExp(/(?=.*[A-Z])/);
  const lowerCaseLetter = new RegExp(/(?=.*[a-z])/);
  const number = new RegExp(/(?=.*[0-9])/);
  const specialCharacter = new RegExp(/[`~!@#$%^&*(),_\-+=[\].?":{}|<>\\;"/]/);

  const validationResult: PasswordValidationResult = {
    inCharacterLimit: passwordValue.length >= 8 && passwordValue.length <= 50,
    hasUpperCaseLetter: upperCaseLetter.test(passwordValue),
    hasLowerCaseLetter: lowerCaseLetter.test(passwordValue),
    hasNumber: number.test(passwordValue),
    hasSpecialCharacter: specialCharacter.test(passwordValue),
    matchingPasswords: !useConfirmPassword || (confirmPasswordValue === passwordValue && passwordValue.length > 0)
  };

  validationResult.valid =
    validationResult.inCharacterLimit &&
    validationResult.hasUpperCaseLetter &&
    validationResult.hasLowerCaseLetter &&
    validationResult.hasNumber &&
    validationResult.hasSpecialCharacter &&
    validationResult.matchingPasswords;

  return validationResult;
}
