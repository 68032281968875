import Axios from 'axios';
import { Features } from './featureFlags';

export const canIUseAsync = async (clientId: string, featureFlagName : Features, defaultValueOnError?: boolean) => {
    try {
        const response = await Axios(`/featureflags/${clientId}/${featureFlagName}`, {
            method: 'GET'
        });

        return response.data;

    } catch (error) {
        console.error(error);
        return defaultValueOnError;
    }
}