import React from 'react';

import './AccountAvatar.scss';

export type AccountAvatarProps = {
  name: string;
};

export default function AccountAvatar({ name }: AccountAvatarProps) {
  return (
    <div className="account-avatar">
      <svg width="100%" height="100%" viewBox="0 0 80 80" preserveAspectRatio="xMinYMin meet">
        <foreignObject width="100%" height="100%" xmlns="http://www.w3.org/1999/xhtml">
          <div>{name?.charAt(0).toUpperCase()}</div>
        </foreignObject>
      </svg>
    </div>
  );
}
