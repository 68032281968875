import React, { useState } from 'react';
import { Input, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import './ChangePassword.scss';
import '../PasswordInput.scss';
import { useTranslation } from 'react-i18next';
import useQuery from '../../hooks/useQuery';
import PasswordInput from '../PasswordInput';
import Loader from '../utility/Loader';
import { getSafeUrlOrRoot } from '../utility/urlSanitization';
import Background from '../Background';

export function ChangePassword() {
  const { t } = useTranslation();
  const searchParams = useQuery();
  const userName = searchParams.get('username');
  const redirectUrl = getSafeUrlOrRoot(searchParams.get('redirect_url'));
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordIsValid, setNewPasswordIsValid] = useState(false);
  const [changed, setChanged] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    if (validated && !newPasswordIsValid) {
      return;
    }

    setShowLoading(true);
    try {
      const result = await Axios('/authenticate/changepassword', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          oldPassword,
          newPassword
        }
      });

      if (result && result.data) {
        setChanged(true);
      }

      setErrorMessage('');
      setValidated(true);
      setShowLoading(false);
    } catch (err) {
      console.log(err);
      setShowLoading(false);
      if (err.response.status >= 500) {
        setErrorMessage(err.response.data.message ? err.response.data.message : null);
      } else if (err.response.status === 401) {
        setErrorMessage(t('InvalidPassword'));
      } else if (err.response.data.errors.includes('reuse')) {
        setErrorMessage(t('Last5PasswordsError'));
      } else {
        setErrorMessage(err.response.data.errors.join(', '));
      }
    }
  }

  function redirect() {
    window.location.replace(redirectUrl);
  }

  let content;

  if (!validated) {
    content = (
      <div>
        <div className="changepassword-subtext">{t('EnterPassword')}</div>
        <Input
          className="form-control"
          type="password"
          name="password"
          id="password"
          placeholder={t('PasswordFieldPlaceholder')}
          autoFocus
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </div>
    );
  } else if (!changed) {
    content = (
      <div>
        <div className="changepassword-subtext">{t('ResetPasswordInstructions')}</div>
        <PasswordInput
          placeholder={t('PasswordFieldPlaceholder')}
          inputClassName="form-control"
          className="password"
          onChange={(password, isValid) => {
            setNewPassword(isValid ? password : null);
            setNewPasswordIsValid(isValid);
          }}
          useConfirmPassword={true}
          autofocus={true}
        />
      </div>
    );
  } else {
    content = <div className="changepassword-subtext">{t('ResetPasswordSuccess')}</div>;
  }

  return (
    <div className="changepassword">
      <Background />
      <div className="changepassword content">
        <div className="top-row header">
          <div className="logo" />
          <div className="logo-wrap-large">
            <div className="logo" />
          </div>
        </div>
        <div className="form-card">
          <header style={{ flexDirection: 'column' }}>
            <h1 className={`changepassword-title`}>{t('ChangePassword')}</h1>
            <div className="changepassword-subtext">{userName}</div>
          </header>
          <form name="changepassword" onSubmit={onSubmit} className="reset-form">
            {errorMessage ? (
              <div className="form-group">
                <div className="error-state">
                  {t('Error')}: {errorMessage}
                </div>
              </div>
            ) : null}
            {content}
            {changed ? (
              <Button className="primary-button" onClick={() => redirect()} loading={showLoading}>
                {t('ReturnToProfile')}
              </Button>
            ) : (
              <Button
                disabled={(!validated && !oldPassword) || (validated && !newPasswordIsValid)}
                className="primary-button"
                loading={showLoading}
                onClick={onSubmit}
              >
                {t('SubmitButton')}
              </Button>
            )}
          </form>
          {validated ? null : (
            <div className="form-secondary">
              <Link to={`/forgotpassword?email=${userName}&redirect_url=${encodeURIComponent(redirectUrl)}`} className="action-button">
                {t('ForgotPassword')}
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="bottom-wrap" />
    </div>
  );
}
