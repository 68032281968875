import { useReducer } from 'react';
import { deepClone } from '../components/utility/deepClone';

export type FormActions = {
  value: any;
  propertyName: string;
};

export type SetValueFn = (propertyName: string, value: any) => void;

function reducer<T>(previousState: T, action: FormActions): T {
  const state = deepClone(previousState) as T;
  state[action.propertyName] = action.value;
  return state;
}

export function useForm<T>(initialState: T): [T, SetValueFn] {
  const [formState, dispatch] = useReducer(reducer, initialState);
  const setValue = (propertyName: string, value: any) => {
    dispatch({ propertyName, value });
  };
  return [formState as T, setValue];
}
