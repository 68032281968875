import Axios from 'axios';

const segmentLoadedKey = 'goformzsegment.loaded';
window[segmentLoadedKey] = false;

//Segment callback does not execute reliably in some browsers' incognito modes, so we use this timeout to prevent blocking
const SEGMENT_CALLBACK_TIMEOUT = 1500;
const skipSegment = () => localStorage.getItem('skipSegment') === 'true'
export const loadSegment = async () => {
  if (skipSegment()) { 
    return false;
  }

  if (window[segmentLoadedKey]) {
    return true;
  }

  if (window.analytics) {
    const response = await Axios<{ segmentApiKey: string }>('/authenticate/segmentapikey');
    if(response.data.segmentApiKey == null) { 
      //failed to laod segment
      console.error('Failed to load segment', response);
      return false;
    }

    window.analytics.load(response.data.segmentApiKey);
    window[segmentLoadedKey] = true;
    return true;
  }
}

export const analyticsPage = async (page?: string) => {
  const segmentLoaded = await loadSegment();
  if(!segmentLoaded) {
    return;
  }

  window.analytics.page(page);
}

export const analyticsTrack = async (eventName: string, data?: { [name: string]: any }) => {
  const segmentLoaded = await loadSegment();
  if(!segmentLoaded) {
    return;
  }

  return new Promise((resolve, reject) => {
    try {      
      if (skipSegment()) { 
        return resolve({});
      }
      if (!window?.analytics?.track) {
        return resolve({});
      }
        
      // eslint-disable-next-line prefer-const
      const segmentTimeout = setTimeout(() => {
        resolve({});
      }, SEGMENT_CALLBACK_TIMEOUT);

      const d = data || {};
      d.source = data.source ? data.source : 'Web';
      window.analytics.track(eventName, d, () => { 
        clearTimeout(segmentTimeout);
        resolve({eventName, ...(data ?? {}) }) }
      );      
    } catch (err) {
      // ignore
      console.error(err);
      reject();
    }
  });
}

export const analyticsIdentify = async (userId, data) => {
  const segmentLoaded = await loadSegment();
  if(!segmentLoaded) {
    return;
  }

  return new Promise((resolve, reject) => {
    
    try {
      if (skipSegment()) { 
        return resolve({});
      }
      if (!window?.analytics?.identify) {
        return resolve({});
      }
        
      // eslint-disable-next-line prefer-const
      const segmentTimeout = setTimeout(() => {
        resolve({});
      }, SEGMENT_CALLBACK_TIMEOUT);
      
      if (userId) {
        window.analytics.identify(userId, data,  
          () => {
            clearTimeout(segmentTimeout);
            resolve({userId, ...(data ?? {}) }) 
          });
      } else {
        //track anonymous
        window.analytics.identify(data, null,  () =>  { 
          clearTimeout(segmentTimeout);
          resolve({...(data ?? {}) }) 
        });
      }
    } catch (err) {
      // ignore
      console.error(err);
      reject();
    }
  });
}


