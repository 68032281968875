import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import GfLogo from '../logo/GfLogo';
import AccountList from './AccountList';
import { IndependentUserClient } from './accounts-types';
import Loader from '../utility/Loader';
import { useClients } from './useClients';
import getQueryVariable, { getQueryVariableFromUrl, getSource } from '../utility/getQueryVariable';
import { useTranslation } from 'react-i18next';

import './AccountSelection.scss';
import Background from '../Background';

export default function AccountSelection() {
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [webUrl, setWebUrl] = useState<string>('');
  const { clients, error, isLoading: isClientLoading } = useClients();
  const errorStatusCode = error?.status;
  const returnUrl = getQueryVariable('ReturnUrl');
  const [switchAccount, setSwitchAccount] = useState<boolean>(false);
  const mobileRedirect = getQueryVariable('mobileRedirect');
  const errorFromQuery = getQueryVariable('error');
  const [errorMessage, setErrorMessage] = useState(errorFromQuery || null);
  const source = getSource(returnUrl) || 'web';

  const { t } = useTranslation();

  useEffect(() => {
    Axios<{ webUrl: string }>('/authenticate/weburl').then(({ data }) => {
      setWebUrl(data.webUrl);
    });

    if (returnUrl) {
      const switchAccount = getQueryVariableFromUrl(returnUrl, 'switchAccount');
      setSwitchAccount(switchAccount);
    }
    if (webUrl && switchAccount) {
      loadIframe(new URL('/switch-account', webUrl).href);
    }
  }, [webUrl, returnUrl]);

  const handleOnAccountSelection = async ({ clientId, name }: IndependentUserClient) => {
    try {
      setIsProcessing(true);
      const { data } = await Axios<{ returnUrl: string }>('/authenticate/accountlogin', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          returnUrl,
          clientId,
          companyName: name
        }
      });

      if (webUrl && switchAccount) {
        await loadIframe(new URL('/switch-account', webUrl).href);
      }
      if (data) {
        window.location.replace(!!mobileRedirect ? `goformz://idp${data.returnUrl}` : data.returnUrl);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || t('UnknownError'));
      setIsProcessing(false);
    }
  };

  const handleLogoutClick = async (event: React.MouseEvent) => {
    event.preventDefault();

    try {
      setIsProcessing(true);
      await loadIframe(new URL('/set-logout', webUrl).href);
      await Axios<void>('authenticate/accountlogout', { method: 'POST', data: {} });

      history.replace('/login');
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || t('UnknownError'));
      setIsProcessing(false);
    }
  };

  if (isClientLoading || !webUrl) {
    return <Loader isLoading />;
  }

  if (errorStatusCode === 401) {
    history.replace('/login');
    return null;
  }

  if (error) {
    console.error(error.message);
  }

  return (
    <div className="account-selection">
      <Background />
      <div className="content">
        <header>
          <GfLogo />
          <div className="header">
            <div>{t('ChooseAnAccount')}</div>
            {errorMessage ? <div className="error-state">{errorMessage}</div> : null}
          </div>
        </header>
        <main className="main-content">
          <Loader isLoading={isProcessing} />
          <AccountList clients={clients} onAccountSelection={handleOnAccountSelection} />
        </main>
        {source === 'web' && webUrl && (
          <footer>
            <a className="profile-link" href={new URL('/iuprofile', webUrl).href}>
              {t('GoFormzProfile')}
            </a>
            <a className="logout-link" role="button" onClick={handleLogoutClick}>
              {t('LogoutLink')}
            </a>
          </footer>
        )}
      </div>
    </div>
  );
}

async function loadIframe(url: string) {
  const iframe = document.createElement('iframe');
  const loadPromise = new Promise((resolve) => {
    iframe.onload = resolve;
  });
  iframe.width = '0';
  iframe.height = '0';
  iframe.src = url;
  document.body.appendChild(iframe);

  return await loadPromise;
}
