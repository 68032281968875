import React, { useState, useRef, useEffect } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { validatePassword } from './utility/passwordUtil';
import './PasswordInput.scss';
import { CommonHtmlAttributeProps } from '@gf-types';

export type PasswordInputProps = CommonHtmlAttributeProps & {
  inputClassName?: string;
  showPassword?: boolean;
  useConfirmPassword?: boolean;

  onChange: (value: string, validationResult: any) => void;
};

export default function PasswordInput(props: PasswordInputProps) {
  const [hasFocused, setHasFocused] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const { t } = useTranslation();
  const [showPassword, setShowPassowrd] = useState(false);
  const focusInput = useRef(null);

  useEffect(() => {
    if (props.autofocus) {
      focusInput.current.focus();
    }
  }, []);

  const onChange = (e) => {
    setPasswordValue(e.target.value);
    props.onChange(e.target.value, validatePassword(e.target.value, props.useConfirmPassword, confirmPasswordValue).valid);
  };

  const onChangeConfirm = (e) => {
    setConfirmPasswordValue(e.target.value);
    props.onChange(passwordValue, validatePassword(passwordValue, props.useConfirmPassword, e.target.value).valid);
  };

  const toggleShowPassword = () => {
    setShowPassowrd(!showPassword);
  };

  const validationResult = validatePassword(passwordValue, props.useConfirmPassword, confirmPasswordValue);
  let characterLimitClassName = '';
  let upperCaseLetterClassName = '';
  let lowerCaseLetterClassName = '';
  let numberClassName = '';
  let specialCharacterClassName = '';
  let passwordMatchClassName = '';

  if (hasFocused) {
    characterLimitClassName = validationResult.inCharacterLimit ? 'valid' : 'invalid';
    upperCaseLetterClassName = validationResult.hasUpperCaseLetter ? 'valid' : 'invalid';
    lowerCaseLetterClassName = validationResult.hasLowerCaseLetter ? 'valid' : 'invalid';
    numberClassName = validationResult.hasNumber ? 'valid' : 'invalid';
    specialCharacterClassName = validationResult.hasSpecialCharacter ? 'valid' : 'invalid';
    passwordMatchClassName = validationResult.matchingPasswords ? 'valid' : 'invalid';
  }

  return (
    <div className="password-input">
      <Input
        placeholder={props.placeholder}
        value={passwordValue || ''}
        className={props.inputClassName}
        onChange={onChange}
        type={showPassword ? 'input' : 'password'}
        onFocus={() => setHasFocused(true)}
        ref={focusInput}
      />
      {props.showPassword ? <Icon name={showPassword ? 'eye slash' : 'eye'} onClick={() => toggleShowPassword()} className="show-password-button" /> : null}
      {props.useConfirmPassword ? (
        <Input
          className={`${props.inputClassName} confirm-password`}
          placeholder={t('ConfirmPasswordPlaceholder')}
          onChange={onChangeConfirm}
          value={confirmPasswordValue || ''}
          type="password"
        />
      ) : null}
      <div className="password-requirement-text">
        <div
          dangerouslySetInnerHTML={{
            __html: t(props.useConfirmPassword ? 'PasswordRequirementWithMatchText' : 'PasswordRequirementText', {
              characterReq: `<span class="${characterLimitClassName}">${t('CharacterRequirementText')}</span>`,
              uppercaseReq: `<span class="${upperCaseLetterClassName}">${t('UppercaseRequirementText')}</span>`,
              lowercaseReq: `<span class="${lowerCaseLetterClassName}">${t('LowercaseRequirementText')}</span>`,
              numberReq: `<span class="${numberClassName}">${t('NumberRequirementText')}</span>`,
              specialCharReq: `<span class="${specialCharacterClassName}">${t('SpecialCharacterRequirementText')}</span>`,
              matchingPasswordsReq: `<span class="${passwordMatchClassName}">${t('MatchingPasswordsRequirementText')}</span>`
            })
          }}
        />
      </div>
    </div>
  );
}
