import React from 'react';

import './GfLogo.scss';

export type GfLogoProps = {
  source?: string;
  onLogoPress?: VoidFunction;
};

export default function GfLogo({ source = 'web', onLogoPress }: GfLogoProps) {
  const handleClick = () => {
    if (source === 'web') {
      window.location.assign('https://www.goformz.com');
      return;
    }

    if (onLogoPress) {
      onLogoPress();
    }
  };

  return <div role="link" aria-label="GoFormz Homepage" className="gf-logo logo" onClick={() => handleClick()}></div>;
}
