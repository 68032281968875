import React from 'react';
import { Input } from 'semantic-ui-react';

export type ValidationInputProps = {
  validationText: string;
  fieldName: string;
  value: unknown;
  inputType?: string;
  placeholder?: string;
  autoFocus?: boolean;

  setFormValue: (field: string, value: string) => void;
  isValid?: (field: string) => boolean;
  setBlurred?: (field: string, blur: boolean) => void;
};

export function ValidationInput(props: ValidationInputProps) {
  return (
    <div className="validationContainer">
      <div className={!props.isValid || props.isValid(props.fieldName) ? 'hidden' : 'validationText'}>{props.validationText}</div>
      <Input
        className={`validationInput ${!props.isValid || props.isValid(props.fieldName) ? '' : 'validationInputInvalid'}`}
        onChange={(e) => props.setFormValue(props.fieldName, e.target.value)}
        onBlur={() => (props.setBlurred ? props.setBlurred(props.fieldName, true) : null)}
        value={props.value}
        type={props.inputType ? props.inputType : 'text'}
        placeholder={props.placeholder}
        data-lpignore="true"
        autoFocus={props?.autoFocus}
      />
    </div>
  );
}

export type ValidationSelectOption = { value: string; display: string };

export type ValidationSelectProps = ValidationInputProps & {
  options: ValidationSelectOption[];
};

export function ValidationSelect(props: ValidationSelectProps) {
  return (
    <div className="validationContainer">
      <div className={!props.isValid || props.isValid(props.fieldName) ? 'hidden' : 'validationText'}>{props.validationText}</div>
      <select
        className={`validationInput select ${!props.isValid || props.isValid(props.fieldName) ? '' : 'validationInputInvalid'} ${
          props.value ? '' : 'disabled'
        }`}
        onChange={(e) => props.setFormValue(props.fieldName, e.target.value)}
        onBlur={() => (props.setBlurred ? props.setBlurred(props.fieldName, true) : null)}
        value={props.value as any}
      >
        {props.placeholder ? (
          <option value="" disabled className="disabled">
            {props.placeholder}
          </option>
        ) : null}
        {props.options.map((x) => (
          <option value={x.value} key={x.value}>
            {x.display}
          </option>
        ))}
      </select>
    </div>
  );
}
