import React from 'react';
import { IndependentUserClient } from './accounts-types';

import './AccountList.scss';
import AccountAvatar from './AccountAvatar';

export type AccountListProps = {
  clients: IndependentUserClient[];
  onAccountSelection: (client: IndependentUserClient) => void;
};

export default function AccountList({ clients = [], onAccountSelection }: AccountListProps) {
  return (
    <ul className="account-list">
      <div className="top-border"></div>
      {clients.map((client) => (
        <li key={client.clientId}>
          <div role="button" aria-label={client.name} className="account-item" onClick={() => onAccountSelection(client)}>
            <div className="avatar-container">
              <AccountAvatar name={client.name} />
            </div>
            {client.name}
            <svg className="arrow" width="12px" height="24px" viewBox="82 584 8 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                transform="translate(86.500000, 590.500000) rotate(-90.000000) translate(-86.500000, -590.500000) translate(81.000000, 588.000000)"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline stroke="#B6C1C6" stroke-width="2" points="0 0.0700550878 5.00031957 4.07037466 10.0706942 0"></polyline>
              </g>
            </svg>
          </div>
        </li>
      ))}
    </ul>
  );
}
